<template>
  <div>
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="">
        <el-input
          clearable
          v-model="searchForm.temName"
          placeholder="请输入模板名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select
          clearable
          v-model="searchForm.temStatus"
          placeholder="请选择状态"
        >
          <el-option
            v-for="(item, i) in statusList"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          v-model="searchForm.dateList"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button type="primary" @click="add">新建模板</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      max-height="460"
      :header-cell-style="{ background: '#E5F0FF' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="60" />
      <!-- <el-table-column prop="id" label="模板ID" width="80" /> -->
      <el-table-column prop="temNum" label="模板编号" width="180" />
      <el-table-column prop="temName" label="模板名称" min-width="100" />
      <el-table-column prop="temContent" label="模板内容" min-width="320" />
      <!-- <el-table-column prop="words" label="字数" /> -->
      <el-table-column prop="temStatus" label="状态" width="110">
        <template #default="scope">
          <el-tag :type="convurtType(scope.row.temStatus)">
            <span>{{ convertTemStatus(scope.row.temStatus) }}</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createUser" label="申请人" width="80" />
      <el-table-column prop="createTime" label="申请时间" width="160" />
      <el-table-column label="操作" align="center" width="160">
        <template #default="scope">
          <span v-if="userName == 'admin'" style="margin-right: 10px">
            <el-button
              v-if="scope.row.temStatus == 1"
              size="small"
              type="primary"
              @click="examine(scope.row)"
              >审核</el-button
            >
            <el-button
              v-else
              size="small"
              type="primary"
              @click="examine(scope.row)"
              disabled
              >已审核</el-button
            >
          </span>
          <el-button size="small" type="danger" @click="remove(scope.row.temId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHandler"
      :current-page="pagination.pageNo"
      :page-sizes="[10, 15, 20]"
      :page-size="pagination.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
    <!-- 新建模板 -->
    <el-dialog v-model="addDialog.isShow" title="新建短信模板" width="500px">
      <el-form
        :model="addDialog.addForm"
        ref="addForm"
        :rules="addDialog.rules"
      >
        <el-form-item label="短信名称" prop="temName">
          <el-input v-model="addDialog.addForm.temName"></el-input>
        </el-form-item>
        <el-form-item label="短信内容" prop="temContent">
          <el-input
            v-model="addDialog.addForm.temContent"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addDialog.isShow = false">取消</el-button>
          <el-button type="primary" @click="addSave('addForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog
      v-model="dialogVisible"
      title="短信模板审核"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="checkForm" :model="checkForm" :rules="rules">
        <el-form-item label="短信名称">
          <el-input readonly v-model="checkForm.temName"></el-input>
        </el-form-item>
        <el-form-item label="短信内容">
          <el-input
            readonly
            v-model="checkForm.temContent"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="模板Id" prop="id">
          <el-input v-model="checkForm.id"></el-input>
          <span class="tips"
            >如果您审核通过此条模板，请将短信模板Id改为第三方已通过的Id,不通过请忽略此提示</span
          >
        </el-form-item> -->
        <div class="ex_btn">
          <el-button type="primary" @click="exSuccess(checkForm, 'checkForm')"
            >审核通过</el-button
          >
          <el-button type="danger" @click="exFail(checkForm, 'checkForm')"
            >审核不通过</el-button
          >
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "messageList",
  data() {
    return {
      userName: localStorage.getItem("userName"),
      searchForm: {
        temName: "",
        temStatus: "",
        dateList: [],
        startTime: "",
        endTime: "",
      },
      querySubmitForm: {
        temName: "",
        temStatus: "",
        dateList: [],
        startTime: "",
        endTime: "",
      },
      statusList: [
        {
          label: "待审核",
          value: 1,
        },
        {
          label: "审核通过",
          value: 2,
        },
        {
          label: "审核不通过",
          value: 3,
        },
      ],
      tableData: [],
      pagination: {
        total: 0, //总条数
        pageNo: 1, // 页数
        length: 15, // 每页的条数
      }, // 分页控件
      addDialog: {
        isShow: false,
        addForm: {
          temName: "",
          temContent: "",
        },
        rules: {
          temName: [
            { required: true, message: "请输入短信名称", trigger: "blur" },
          ],
          temContent: [
            { required: true, message: "请输入短信内容", trigger: "blur" },
          ],
        },
      },
      dialogVisible: false,
      exObj: {},
      checkForm: {
        temName: "",
        temContent: "",
      },
      rules: {
        id: [{ required: true, message: "请输入短信Id", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getTableData();
  },
  watch: {
    "addDialog.isShow"(newVal) {
      if (!newVal) {
        this.$refs.addForm.resetFields();
        this.addDialog.addForm.temName = "";
        this.addDialog.addForm.temContent = "";
      }
    },
  },
  methods: {
    // 获取列表数据
    getTableData() {
      this.$http
        .request("getTemplateList", {
          // 参数
          ...this.querySubmitForm,
          page: this.pagination.pageNo,
          limit: this.pagination.length,
        })
        .then((res) => {
          let datas = res.result.data;
          datas.forEach((item) => {
            if (item.createUser == "" || item.createUser == null) {
              item.createUser = "admin";
            }
          });
          this.tableData = datas;
          this.pagination.total = res.result.count;
        })
        .catch(() => {});
    },
    // 查询
    query() {
      if (this.searchForm.dateList) {
        this.searchForm.startTime = this.searchForm.dateList[0];
        this.searchForm.endTime = this.searchForm.dateList[1];
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }

      this.querySubmitForm = Object.assign({}, this.searchForm);
      this.getTableData((this.pagination.pageNo = 1));
    },
    // 新建模板
    add() {
      this.addDialog.isShow = true;
    },
    // 新建保存
    addSave(formName) {
      let words = null;
      let textNum = this.addDialog.addForm.temContent.length;
      if (textNum < 70) {
        words = 1;
      } else {
        words = 0;
      }
      let postData = {
        temName: this.addDialog.addForm.temName,
        temContent: this.addDialog.addForm.temContent,
        words,
      };
      console.log(textNum, "000");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .request("addTemplate", postData)
            .then((res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.addDialog.isShow = false;
              this.getTableData();
            })
            .catch(() => {});
        }
      });
    },
    // 审核
    examine(row) {
      this.dialogVisible = true;

      this.checkForm.temName = row.temName;
      this.checkForm.temContent = row.temContent;
      this.checkForm.temId = row.temId;
      // this.checkForm.id = row.id;
    },
    // 审核-通过
    exSuccess(row, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(row, "555");
          this.$http
            .request("updateStatus", {
              // createTime: new Date(row.createTime),
              // applyDate: new Date(),
              temId: row.temId,
              temStatus: 2,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.dialogVisible = false;
              this.getTableData();
            })
            .catch(() => {});
        }
      });
    },
    // 审核-不通过
    exFail(row, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .request("updateStatus", {
              // ...row,
              // createTime: new Date(row.createTime),
              // applyDate: new Date(),
              temId: row.temId,
              temStatus: 3,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.dialogVisible = false;
              this.getTableData();
            })
            .catch(() => {});
        }
      });
    },
    // 删除
    remove(id) {
      this.$confirm("确定要删除此条模板？", "提示", {
        showClose: false,
        type: "warning",
      })
        .then(() => {
          this.$http
            .request("deleteTemplate", {
              // 参数
              id,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getTableData();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 换每页条数
    sizeChangeHandler(length) {
      this.pagination.length = length;
      this.getTableData();
    },
    // 换页
    currentChangeHandler(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getTableData();
    },
    convertTemStatus(val) {
      if (val == 1) {
        return "待审核";
      } else if (val == 2) {
        return "审核通过";
      } else if (val == 3) {
        return "审核不通过";
      }
    },
    convurtType(val) {
      if (val == 1) {
        return "";
      } else if (val == 2) {
        return "success";
      } else if (val == 3) {
        return "danger";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 30px;
  text-align: right;
}
.ex_box {
  div {
    margin-bottom: 15px;
    line-height: 25px;
  }
}

.tips {
  color: red;
  font-size: 12px;
  line-height: 20px;
}
</style>